import xmljs from 'xml-js';

import { formatGamesPlayedData } from './util';


const API = 'https://www.boardgamegeek.com/xmlapi2';
const BGG_USERNAME = 'benschel';

const xmlOptions = {
    compact: true,
    trim: true,
    nativeType: true,
    ignoreDeclaration: true
};


class BggApi {

    getGamePlayData(gamePlays = [], page = 1) {
        return new Promise((resolve, reject) => (fetch(`${API}/plays?username=${BGG_USERNAME}&page=${page}`)
            .then(response => {
                if (response.status !== 200) {
                    throw `${response.status}: ${response.statusText}`;
                }

                response.text()
                .then(xml => {
                    let data = xmljs.xml2js(xml, xmlOptions);

                    let num_pages = Math.ceil(data.plays._attributes.total / 100);

                    gamePlays = gamePlays.concat(data.plays.play);
                   
                    if (num_pages !== page) {
                        page++;

                        this.getGamePlayData(gamePlays, page)
                        .then(resolve).catch(reject);
                    } else {

                        let cleanData = formatGamesPlayedData(gamePlays);
                        resolve(cleanData);
                    }
                }).catch(reject);
            }).catch(reject)));
    }
}

export default BggApi;
