import React, { Component } from 'react';

import Game from './components/Game';

import BggApi from './libs/bggApi';

import './css/App.css';
import logo from './images/logo.svg';
import meeple from './images/meeple.svg';
import cat from './images/cat.svg';


const bggApi = new BggApi();

class App extends Component {
    constructor() {
        super();

        this.renderGameList = this.renderGameList.bind(this);

        this.state = {
            gameplayData: [],
            dataLoaded: false
        };
    }

    getGamePlayData() {
        bggApi.getGamePlayData()
        .then(data => {
            this.setState({
                gameplayData: data,
                dataLoaded: true
            });
        });
    }

    componentDidMount() {
        this.getGamePlayData();
    }

    renderGameList(games) {
        if (!this.state.dataLoaded) {
            return (<p className="loading">Loading...</p>);
        }

        return (
            <React.Fragment>
                {games.map((game, index) => (
                    <tr key={index} className="game-list-row">
                        <Game
                            name={game.name}
                            totalGamesPlayed={game.total_games_played}
                            benWins={game.wins.ben}
                            amberWins={game.wins.amber}
                        />
                    </tr>
                ))}
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="App">
                <div className="header">
                    <img src={logo} className="App-logo" alt="Amber vs Ben" />
                </div>

                <table className="game-list-container">
                    <thead>
                        <tr className="game-list-header">
                            <th>
                                <img src={meeple} className="meeple" />
                                <h2 className="header-name amber-header">Amber</h2>
                            </th>
                            <th>
                                <a href="https://www.instagram.com/phoebe_cat_bk/?hl=en" target="_blank"><img src={cat} className="phoebe" /></a>
                            </th>
                            <th>
                                <h2 className="header-name ben-header">Ben</h2>
                                <img src={meeple} className="meeple" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderGameList(this.state.gameplayData)}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default App;
