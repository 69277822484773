import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Pluralize from 'react-pluralize';

import '../css/Game.css';
import trophy from '../images/trophy.svg';


class Game extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        totalGamesPlayed: PropTypes.number.isRequired,
        benWins: PropTypes.number.isRequired,
        amberWins: PropTypes.number.isRequired
    };

    constructor() {
        super();
    }

    render() {
        const { name, totalGamesPlayed, benWins, amberWins  } = this.props;

        return (
            <React.Fragment>
                <td className="game-col first-col">
                    <h1 className="win-count">{amberWins}</h1>
                    <h6 className="win-label"><Pluralize singular="Win" count={amberWins} showCount={false} /></h6>

                    {(amberWins > benWins) &&
                        <img src={trophy} className="trophy" />
                    }
                </td>
                <td className="game-col middle-col">
                    <h1 className="game-name">{name}</h1>
                    <h2 className="play-count"><Pluralize singular="Game" count={totalGamesPlayed} /></h2>
                </td>
                <td className="game-col last-col">
                    <h1 className="win-count">{benWins}</h1>
                    <h6 className="win-label"><Pluralize singular="Win" count={benWins} showCount={false} /></h6>

                    {(benWins > amberWins) &&
                        <img src={trophy} className="trophy" />
                    }
                </td>
            </React.Fragment>
        );
    }
}

export default Game;
