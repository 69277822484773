//
// Utilities
//
import xmljs from 'xml-js';


export const handleXmlResponse = (response) => {
    const options = {
        compact: true,
        trim: true,
        nativeType: true,
        ignoreDeclaration: true
    };

    return response.text()
    .then(xml => {
        return xmljs.xml2js(xml, options);
    });
};


export const BEN = 'Ben';
export const AMBER = 'Amber';

const parsePlayerOutcome = (players) => {
    let playerOutcome = {
        ben: 0,
        amber: 0,
        invalidPlayer: false
    };

    if (players.length !== 2) {
        playerOutcome['invalidPlayer'] = true;

        return playerOutcome;
    }

    players.forEach((player) => {
        switch (player._attributes.name) {
            case AMBER:
                playerOutcome['amber'] = parseInt(player._attributes.win);
                break;
            case BEN:
                playerOutcome['ben'] = parseInt(player._attributes.win);
                break;
            default:
                playerOutcome['invalidPlayer'] = true;
                break;
        }
    });

    return playerOutcome;
};


export const formatGamesPlayedData = (plays) => {
    let gamesPlayed = [];

    plays.forEach((play) => {
        let bggId = play.item._attributes.objectid;
        let name = play.item._attributes.name;

        // Some play records don't have any player data
        let players = play.players ? play.players.player : false;

        // If the players is either false or not an array we don't want to use
        // this play session
        if (!players || !Array.isArray(players)) {
            return;
        }

        let playerOutcome = parsePlayerOutcome(players);

        if (playerOutcome.invalidPlayer) {
            return;
        }

        let index = gamesPlayed.findIndex((game) => (game.bgg_id === bggId));

        if (index === -1) {
            gamesPlayed.push({
                name: name,
                bgg_id: bggId,
                total_games_played: 1,
                wins: {
                    ben: playerOutcome.ben,
                    amber: playerOutcome.amber
                }
            })
        } else {
            gamesPlayed[index].total_games_played++;
            gamesPlayed[index].wins.ben += playerOutcome.ben;
            gamesPlayed[index].wins.amber += playerOutcome.amber;
        }
    });

    return gamesPlayed;
};
